.tabs-body-expanded {
  &.mat-mdc-tab-group,
  .mat-mdc-tab-body-wrapper {
    height: 100%;
  }
}

.custom-mat-tab.mat-mdc-tab-group,
.custom-mat-tab .mat-mdc-tab-nav-bar {
  --mat-tab-header-disabled-ripple-color: var(--gray-600);
  --mat-tab-header-pagination-icon-color: theme('colors.neutral.700');
  --mat-tab-header-inactive-label-text-color: theme('colors.disabled');
  --mat-tab-header-active-label-text-color: theme('colors.sky.500');
  --mat-tab-header-inactive-focus-label-text-color: theme('colors.disabled');
  --mat-tab-header-inactive-hover-label-text-color: theme('colors.disabled');
  --mat-tab-header-active-focus-label-text-color: theme('colors.sky.500');
  --mat-tab-header-active-hover-label-text-color: theme('colors.sky.500');
  --mat-tab-header-active-focus-indicator-color: theme('colors.sky.500');
  --mat-tab-header-active-hover-indicator-color: theme('colors.sky.500');

  --mdc-tab-indicator-active-indicator-color: theme('colors.sky.500');
  --mat-tab-header-active-ripple-color: theme('colors.neutral.50');
  --mat-tab-header-inactive-ripple-color: theme('colors.neutral.50');
  --mat-tab-header-label-text-size: theme('fontSize.md.0');

  .mat-mdc-tab-header {
    --mat-tab-header-label-text-size: theme('fontSize.sm.0');
  }

  .mat-mdc-tab-labels,
  .mat-mdc-tab-links {
    @apply gap-2;
    position: relative;

    &::after {
      @apply bg-gray-200;
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1px;
    }
  }

  .mat-mdc-tab.mdc-tab,
  .mat-mdc-tab-link.mdc-tab {
    @apply font-medium-exam px-5 py-3;
    height: auto;
    min-width: unset;

    &:hover {
      .mdc-tab__ripple::before {
        opacity: 1;
      }
    }

    .mdc-tab-indicator__content--underline.mdc-tab-indicator__content {
      border-top-width: 2px;
      --mat-tab-animation-duration: 500ms;
    }

    .mdc-tab__content .mdc-tab__text-label {
      dojo-icon {
        + * {
          @apply ml-2;
        }
      }
    }
  }

  &.tabs-header-offset {
    .mat-mdc-tab-header {
      @apply mb-8;
    }
  }
}
