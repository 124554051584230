.mdc-text-field {
  --mat-form-field-hover-state-layer-opacity: 0;
  --mat-form-field-focus-state-layer-opacity: 0;
  --mdc-filled-text-field-caret-color: theme('colors.blue.800');

  &:not(.mdc-text-field--disabled) .mdc-text-field__input {
    color: theme('colors.stone.800');
    font-family: theme('fontFamily.primary');

    &::placeholder {
      @apply text-md;
      --mdc-filled-text-field-input-text-placeholder-color: theme('colors.disabled');
    }

    .read-only & {
      color: theme('fontFamily.secondary');
    }
  }

  &.mdc-text-field--disabled .mdc-text-field__input {
    @apply text-disabled;
    font-family: theme('fontFamily.primary');

    .dark & {
      color: theme('colors.stone.600');
    }

    &::placeholder {
      --mdc-filled-text-field-input-text-placeholder-color: theme('colors.disabled');
    }
  }
}

//mat-chip-grid to be removed from here and added to the its own style file
.mat-mdc-form-field-type-mat-input,
.mat-mdc-form-field-type-mat-chip-grid {
  &:hover {
    .mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) {
      border-color: theme('colors.zinc.400');
    }
  }

  .mdc-text-field {
    &--filled:not(.mdc-text-field--disabled) {
      background: transparent;

      .read-only & {
        background: theme('colors.neutral.50');
      }

      .dark & {
        background: transparent;
      }
    }
  }

  &.mat-focused {
    .mdc-text-field:not(.mdc-text-field--disabled) {
      border-color: theme('colors.sky.500');

      .dark & {
        border-color: theme('colors.sky.500');
      }
    }

    &:hover {
      .mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) {
        border-color: theme('colors.sky.500');

        .dark & {
          border-color: theme('colors.sky.500');
        }
      }
    }
  }
}

.mat-mdc-form-field-has-icon-suffix,
.mat-mdc-form-field-has-icon-prefix {
  dojo-icon,
  fa-icon {
    svg {
      width: 14px;
      height: 14px;
    }
  }
}

.mat-mdc-text-field-wrapper {
  transition: border-color 0.25s ease-in;

  .mat-mdc-form-field-has-icon-prefix.mat-mdc-form-field & {
    padding-left: 13px; //exception
    --mat-mdc-form-field-label-offset-x: 0;
  }

  .mat-mdc-form-field-has-icon-suffix.mat-mdc-form-field & {
    padding-right: 13px; //exception
  }
}

//autofill hack https://css-tricks.com/almanac/selectors/a/autofill/
//it should fix the size but it doesn't input:-webkit-autofill::first-line
input.mdc-text-field__input:-webkit-autofill {
  -webkit-text-fill-color: var(--typography-primary);
  -webkit-box-shadow: 0 0 0 40rem var(--gray-200) inset !important;

  &:hover,
  &:focus {
    -webkit-text-fill-color: var(--typography-primary);
    -webkit-box-shadow: 0 0 0 40rem var(--gray-200) inset !important;
  }
}

.custom-input-number .mat-mdc-form-field-infix {
  &:hover {
    &::after,
    &::before {
      opacity: 1;
    }
  }

  &::after,
  &::before {
    @apply text-typography-secondary bg-gray-300;
    position: absolute;
    right: 0;
    width: 16px;
    height: 8px;
    font: var(--fa-font-regular);
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
  }

  &::before {
    content: '\f106';
    top: 7px;
  }

  &::after {
    content: '\f107';
    bottom: 8px;
  }

  input[type='number']::-webkit-inner-spin-button {
    opacity: 0;
  }
}

.full-width-field .mat-mdc-form-field-infix {
  width: 100%;
}
