@use 'variables' as *;
@use 'mixins' as *;
@use 'breakpoints-mixins' as *;

$scrollbar-width: 8px;

::selection {
  background-color: rgb(58, 157, 202, 0.3);
}

//Safari will release soon this
//https://caniuse.com/mdn-css_properties_scrollbar-gutter_stable
.space-for-scroll {
  @apply small:pr-10 small:pl-12 overflow-y-scroll pl-6 pr-4;

  .plt-iphone.plt-ios & {
    @apply small:pr-12 pr-6;
  }
}

.common-ion-title {
  font-family: theme('fontFamily.primary');
  font-weight: theme('fontWeight.semibold');
  text-align: center;
  font-size: theme('fontSize.lg.0');
  line-height: theme('fontSize.lg.1');
  color: theme('colors.primary-exam');
  letter-spacing: normal;

  @include start-from-small {
    position: static;
  }
}

.common-small-ion-title {
  font-family: theme('fontFamily.primary');
  font-weight: theme('fontWeight.semibold');
  text-align: center;
  font-size: theme('fontSize.md.0');
  line-height: theme('fontSize.md.1');
  color: theme('colors.primary-exam');
  letter-spacing: normal;

  @include start-from-small {
    position: static;
  }
}

//used for native app
.safe-area-bottom {
  padding-bottom: env(safe-area-inset-bottom, 20px);
}

.safe-area-top {
  padding-top: env(safe-area-inset-top, 30px);
}

html {
  scroll-behavior: smooth;
}

body {
  @apply font-primary font-normal-exam text-primary-exam bg-white text-sm;
  margin: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
}

ol {
  list-style-type: decimal;
}

ul {
  list-style-type: disc;
}

::before,
::after {
  border-color: unset;
}

code {
  @apply text-typography-secondary;
  white-space: break-spaces;
}

// scrollbar starts
::-webkit-scrollbar {
  width: $scrollbar-width;
  height: 8px;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  @apply bg-gray-200;
  border: 1px solid var(--gray-200);
  border-radius: 0;

  &:hover,
  &:active {
    @apply bg-gray-300;
  }
}

::-webkit-scrollbar-track {
  background: transparent;
  border: none;
  border-radius: 0;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

// scrollbar ends

p {
  margin-top: 0;
  margin-bottom: 0.3rem;
}

a {
  text-decoration: none;
  transition: opacity 0.25s ease-in;
  cursor: pointer;

  &:not(.mdc-button, .mat-menu-link, .dashed-link, .primary-link, .accent-link, .custom-mat-menu-item):hover {
    opacity: 0.85;
    text-decoration: none;
  }

  &.mat-menu-link:hover {
    color: inherit;
    text-decoration: none;
  }

  &:focus-visible:not(.mdc-button, .mat-menu-link, .dashed-link, .primary-link, .accent-link, .custom-mat-menu-item) {
    opacity: 0.85;
    text-decoration: underline;
    outline: none;
  }

  &.dashed-link,
  &.dashed-link:focus,
  &.dashed-link:visited {
    color: theme('colors.typography.primary');
    border-bottom: 1px dashed currentColor;
  }

  &.dashed-link:hover {
    color: theme('colors.typography.primary');
    border-style: solid;
  }

  &.dashed-link[disabled] {
    color: theme('colors.typography.secondary');
  }

  // if changed here, change it accordingly in .button-primary-link and .button-accent-link in _mat-button.scss
  &.primary-link {
    color: theme('colors.primary.700');

    &:hover {
      color: theme('colors.primary.800');
    }
  }

  &.accent-link {
    color: theme('colors.yellow.800');

    &:hover {
      color: theme('colors.yellow.900');
    }
  }

  &.primary-link,
  &.accent-link {
    font-weight: theme('fontWeight.medium');
    transition: color 0.25s ease-in;

    dojo-icon {
      vertical-align: -2px;
    }

    .dark & {
      font-weight: theme('fontWeight.medium-dark');
    }
  }
}

//To fix bootstrap lack
[hidden] {
  display: none !important;
}

.aligner {
  display: flex;
  align-items: center;
  justify-content: center;
}

* {
  &:focus-visible {
    outline: 1px solid var(--focus-color);
  }
}

router-outlet {
  display: none;
}

.center-absolute {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button {
  display: none;
}

/* Elements with disabled attribute will have pointer events disabled */
[disabled] {
  pointer-events: none;
}

/* This prevents it from being disabled when it has the disabled attribute with a value of false. */
[disabled='false'] {
  pointer-events: initial;
}

//introduced it as tailwind doesn't have this class
.scrollbar-gutter-stable {
  scrollbar-gutter: stable;
}

.standard-container {
  @apply mx-auto w-full;
  max-width: calc(#{$content-max-width} + #{$container-padding-lg} * 2);
  padding-left: $container-padding-lg;
  padding-right: $container-padding-lg;

  @media (max-width: 768px) {
    max-width: calc(#{$content-max-width} + #{$container-padding-sm} * 2);
    padding-left: $container-padding-sm;
    padding-right: $container-padding-sm;
  }
}

.small-container {
  @apply mx-auto w-full;
  max-width: calc(#{$small-content-max-width});
}

.tiny-container {
  @apply mx-auto w-full;
  max-width: calc(#{$tiny-content-max-width});
  padding-left: 1rem;
  padding-right: 1rem;
}

// needed for ngClass bindings
@each $color in ('red', 'blue', 'green', 'yellow', 'purple', 'pink', 'indigo', 'orange', 'teal', 'sky') {
  .bg-dynamic-#{$color}-50 {
    @apply bg-#{$color}-50;
  }
  @for $shade from 1 through 9 {
    .bg-dynamic-#{$color}-#{$shade}00 {
      @apply bg-#{$color}-#{$shade}00;
    }
  }

  .text-dynamic-#{$color}-50 {
    @apply text-#{$color}-50;
  }
  @for $shade from 1 through 9 {
    .text-dynamic-#{$color}-#{$shade}00 {
      @apply text-#{$color}-#{$shade}00;
    }
  }
}

// for sticky active assessment box up top
.sticky-wrapper {
  $top: theme('spacing.8');
  top: $top;
  position: sticky;
  z-index: 100;
  height: 220px;
  width: 100%;
  pointer-events: none;

  @media (max-width: 768px) {
    height: 210px;
  }
}

.sidebar-content {
  @apply small:p-6 rounded-[1rem] border border-neutral-200 p-4;
}

.topic-cell {
  white-space: pre-line !important;
  line-height: 1.2 !important;
  padding: 10px 5px 10px 10px;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;

  @media (max-width: 768px) {
    padding: 5px;
  }
}

// Route transitions
@keyframes slideIn {
  from {
    transform: translateY(60px);
    z-index: 10000;
    opacity: 0;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

::view-transition-old(*),
::view-transition-new(*) {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
::view-transition-new(*) {
  animation-name: slideIn;
}
