.custom-selection-list-checkbox {
  --border-width: 1px;
  --border-bottom-width: 4px;
  --mdc-list-list-item-one-line-container-height: auto;
  --mdc-list-list-item-focus-label-text-color: theme('colors.secondary');
  --mdc-list-list-item-hover-label-text-color: theme('colors.secondary');

  &.mdc-list {
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(315px, 1fr));
    gap: theme('spacing.3');

    @media screen and (max-width: 500px) {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }

    .mat-mdc-list-item {
      padding: calc(theme('spacing.4') - #{var(--border-width)}) theme('spacing.4')
        calc(theme('spacing.4') - #{var(--border-bottom-width)});
      border-radius: theme('spacing.3');
      border-style: solid;
      border-color: transparent;
      border-width: var(--border-width) var(--border-width) var(--border-bottom-width) var(--border-width);
      height: auto;
      overflow: visible;

      &::after {
        content: '';
        position: absolute;
        bottom: calc(-1 * var(--border-width));
        left: calc(-1 * var(--border-width));
        right: calc(-1 * var(--border-width));
        top: calc(-1 * var(--border-width));
        z-index: 0;
        border: var(--border-width) solid theme('colors.neutral.200');
        border-radius: theme('spacing.3');
        box-shadow: 0 calc(var(--border-bottom-width) - var(--border-width)) 0;
        color: theme('colors.neutral.200');
        background: white;
      }

      &:not(.mdc-list-item--disabled) {
        &:active {
          $translate: calc(var(--border-bottom-width) - var(--border-width));
          transform: translateY(#{$translate}) translateZ(0);

          &::after {
            border-color: theme('colors.sky.300');
            color: theme('colors.sky.300');
            box-shadow: none;
          }

          &::before {
            background: transparent;
          }
        }

        &:hover {
          &::after {
            background: theme('colors.stone.50');
          }

          &::before {
            background: transparent;
          }
        }

        &:focus {
          &::before {
            opacity: 0;
          }

          &::after {
            border-color: theme('colors.sky.300');
            color: theme('colors.sky.300');
            background: theme('colors.stone.50');
          }
        }
      }

      .number {
        border-color: theme('colors.neutral.200');
      }

      &[aria-selected='true'] {
        &::after {
          border-color: theme('colors.sky.300');
          color: theme('colors.sky.300');
          background: theme('colors.sky.50');
        }

        .mdc-list-item__primary-text {
          color: theme('colors.sky.600');

          .inner-component * {
            color: theme('colors.sky.600') !important;
          }
        }

        .number {
          border-color: theme('colors.sky.400');
        }
      }
    }

    .billing-list & {
      --mdc-list-list-item-focus-label-text-color: theme('colors.neutral.0');
      --mdc-list-list-item-hover-label-text-color: theme('colors.neutral.0');

      .mat-mdc-list-item {
        &::after {
          border: var(--border-width) solid rgba(62, 61, 238, 0.2);
          color: rgba(91, 102, 249, 0.29);
          background: rgba(91, 102, 249, 0.2);
        }

        &:not(.mdc-list-item--disabled) {
          &:active {
            &::after {
              border-color: theme('colors.amber.500');
              color: theme('colors.amber.500');
            }

            &::before {
              background: transparent;
            }
          }

          &:hover {
            &::after {
              background: rgba(91, 102, 249, 0.3);
              color: rgba(91, 102, 249, 0.39);
            }
          }

          &:focus {
            &::before {
              opacity: 0;
            }

            &::after {
              border-color: theme('colors.amber.500');
              color: theme('colors.amber.500');
              background: rgba(249, 161, 7, 0.2);
            }
          }
        }

        &[aria-selected='true'] {
          &::after {
            border-color: theme('colors.amber.500');
            color: theme('colors.amber.500');
            background: rgba(249, 161, 7, 0.2);
          }

          &:hover::after {
            color: theme('colors.amber.500');
          }

          .mdc-list-item__primary-text {
            color: theme('colors.neutral.0');
          }
        }
      }

      .mdc-list-item__primary-text {
        color: theme('colors.neutral.0');
      }
    }

    .mdc-list-item__primary-text {
      font-family: theme('fontFamily.primary');
      color: theme('colors.secondary');
      font-size: theme('fontSize.md.0');
      line-height: theme('fontSize.md.1');
      font-weight: 500;
    }
  }

  .mdc-list-item--with-trailing-radio,
  .mdc-list-item--with-trailing-checkbox {
    .mdc-list-item__end {
      margin-right: 0;
      display: flex;
    }
  }

  .hide-indicator .mdc-list-item__end {
    display: none;
  }

  .mdc-list-item__start,
  .mdc-list-item__end,
  .mdc-list-item__content {
    z-index: 1;
  }
}

.custom-selection-list-checkbox-appearance {
  --mdc-list-list-item-one-line-container-height: auto;
  --mdc-list-list-item-focus-state-layer-color: theme('colors.neutral.300');

  &.mdc-list {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: theme('spacing.3');

    .mat-mdc-list-item {
      height: auto;
      padding-top: theme('spacing[0.5]');
      padding-bottom: theme('spacing[0.5]');
      border-radius: theme('spacing.1');
    }
  }

  .mdc-list-item:hover::before {
    background: transparent;
  }

  .mdc-list-item.mdc-list-item--with-one-line .mdc-list-item__start {
    margin-left: 0;
    margin-right: theme('spacing.2');
    margin-top: calc(-1 * theme('spacing[0.5]'));
  }

  .mdc-list-item--with-trailing-radio,
  .mdc-list-item--with-trailing-checkbox {
    .mdc-list-item__end {
      margin-right: 0;
      display: flex;
    }
  }

  .mdc-list-item__primary-text {
    font-family: theme('fontFamily.primary');
    color: theme('colors.primary-exam');
    font-size: theme('fontSize.md.0');
    line-height: theme('fontSize.md.1');
    font-weight: 500;
  }
}

.mat-mdc-list-option {
  .mdc-checkbox {
    --mdc-checkbox-state-layer-size: 20px;

    .mat-mdc-checkbox-touch-target {
      height: 100%;
      width: 100%;
    }

    .mdc-checkbox__checkmark {
      width: 10px;
      height: 10px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      --mdc-checkbox-selected-checkmark-color: var(--white);
    }

    .mdc-checkbox__background {
      width: 20px;
      height: 20px;
      border-radius: 4px;
      border-width: 1px;
    }

    .mdc-checkbox__ripple {
      display: none;
    }

    .mdc-checkbox__background {
      --mdc-checkbox-unselected-icon-color: var(--neutral-200);
      --mdc-checkbox-selected-hover-icon-color: var(--sky-400);
      --mdc-checkbox-unselected-focus-icon-color: var(--sky-400);
      --mdc-checkbox-selected-pressed-icon-color: var(--sky-500);
      --mdc-checkbox-disabled-unselected-icon-color: var(--neutral-200);
      --mdc-checkbox-unselected-pressed-icon-color: var(--sky-400);
      --mdc-checkbox-selected-icon-color: var(--sky-400);
      --mdc-checkbox-selected-focus-icon-color: var(--sky-400);
      --mdc-checkbox-unselected-hover-icon-color: var(--sky-400);

      .dark & {
        --mdc-checkbox-unselected-hover-icon-color: var(--gray-500);
        --mdc-checkbox-selected-icon-color: var(--primary-400);
        --mdc-checkbox-selected-focus-icon-color: var(--primary-400);
        --mdc-checkbox-selected-hover-icon-color: var(--primary-500);
      }

      .ng-touched.ng-invalid .mat-mdc-checkbox[required] & {
        --mdc-checkbox-unselected-icon-color: var(--red-600);
      }
    }
  }

  .mdc-radio {
    --mdc-radio-state-layer-size: 20px;

    --mdc-radio-disabled-selected-icon-color: var(--neutral-200);
    --mdc-radio-disabled-unselected-icon-color: var(--neutral-200);
    --mdc-radio-unselected-hover-icon-color: var(--sky-400);
    --mdc-radio-unselected-icon-color: var(--neutral-200);
    --mdc-radio-unselected-pressed-icon-color: var(--sky-400);
    --mdc-radio-selected-focus-icon-color: var(--sky-400);
    --mdc-radio-selected-hover-icon-color: var(--sky-400);
    --mdc-radio-selected-icon-color: var(--sky-400);
    --mdc-radio-selected-pressed-icon-color: var(--sky-400);
  }
}
