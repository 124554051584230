.mat-mdc-tooltip,
.mtx-mdc-tooltip {
  --mdc-plain-tooltip-supporting-text-font: theme('fontFamily.primary');
  --mdc-plain-tooltip-supporting-text-size: theme('fontSize.sm.0');
  --mdc-plain-tooltip-supporting-text-line-height: theme('fontSize.sm.1');
  --mdc-plain-tooltip-container-color: var(--neutral-800);
  --mdc-plain-tooltip-supporting-text-color: theme('colors.neutral.0');
  --mdc-plain-tooltip-supporting-text-weight: theme('fontWeight.medium-exam');
  --mdc-plain-tooltip-container-shape: 0.75rem;
  --mdc-plain-tooltip-supporting-text-tracking: 0;

  .dark & {
    --mdc-plain-tooltip-supporting-text-weight: theme('fontWeight.normal-dark');
  }

  .dark & {
    --mdc-plain-tooltip-container-color: var(--gray-100);
    --mdc-plain-tooltip-supporting-text-color: var(--typography-primary);
  }

  .mdc-tooltip__surface {
    @apply px-3 py-2;
  }
}

.mtx-mdc-tooltip {
  .mdc-tooltip__surface {
    min-width: 0;
    text-align: left;
  }
}
