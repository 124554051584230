@keyframes hotToastErrorCircleAnimation {
  from {
    opacity: 0;
    transform: scale(0) rotate(45deg);
  }

  to {
    opacity: 1;
    transform: scale(1) rotate(45deg);
  }
}

@keyframes hotToastFirstLineAnimation {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes hotToastSecondLineAnimation {
  from {
    opacity: 0;
    transform: scale(0) rotate(90deg);
  }

  to {
    opacity: 1;
    transform: scale(1) rotate(90deg);
  }
}

.hot-toast-error-icon {
  animation: var(
    --hot-toast-error-icon-animation,
    hotToastErrorCircleAnimation 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards
  );
  animation-delay: var(--hot-toast-error-icon-animation-delay, 100ms);
  background: var(--hot-toast-error-icon-background, var(--error-primary, #ff4b4b));
  border-radius: var(--hot-toast-error-icon-border-radius, 10px);
  height: var(--hot-toast-error-icon-height, 20px);
  opacity: var(--hot-toast-error-icon-opacity, 0);
  position: var(--hot-toast-error-icon-position, relative);
  transform: var(--hot-toast-error-icon-transform, rotate(45deg));
  width: var(--hot-toast-error-icon-width, 20px);

  @media (prefers-reduced-motion: reduce) {
    animation-duration: var(--hot-toast-error-icon-reduced-motion-animation-duration, 0ms);
  }
}

.hot-toast-error-icon::after,
.hot-toast-error-icon::before {
  animation: var(--hot-toast-error-icon-after-before-animation, hotToastFirstLineAnimation 0.15s ease-out forwards);
  animation-delay: var(--hot-toast-error-icon-after-before-animation-delay, 150ms);
  background: var(--hot-toast-error-icon-after-before-background, var(--error-secondary, #fff));
  border-radius: var(--hot-toast-error-icon-after-before-border-radius, 3px);
  bottom: var(--hot-toast-error-icon-after-before-bottom, 9px);
  content: var(--hot-toast-error-icon-after-before-content, '');
  height: var(--hot-toast-error-icon-after-before-height, 2px);
  left: var(--hot-toast-error-icon-after-before-left, 4px);
  opacity: var(--hot-toast-error-icon-after-before-opacity, 0);
  position: var(--hot-toast-error-icon-after-before-position, absolute);
  width: var(--hot-toast-error-icon-after-before-width, 12px);

  @media (prefers-reduced-motion: reduce) {
    animation-duration: var(--hot-toast-error-icon-after-before-reduced-motion-animation-duration, 0ms);
  }
}

.hot-toast-error-icon::before {
  animation: var(--hot-toast-error-icon-before-animation, hotToastSecondLineAnimation 0.15s ease-out forwards);
  animation-delay: var(--hot-toast-error-icon-before-animation-delay, 180ms);
  transform: var(--hot-toast-error-icon-before-transform, rotate(90deg));

  @media (prefers-reduced-motion: reduce) {
    animation-duration: var(--hot-toast-error-icon-before-reduced-motion-animation-duration, 0ms);
  }
}
