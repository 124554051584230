.mat-radio-button {
  display: flex;
  align-items: center;
  width: fit-content;

  & + & {
    @apply mt-2;
  }
}

.mat-radio-group {
  display: flex;
  flex-direction: column;
  position: relative;

  &:not(:last-of-type) {
    @apply mb-4;
  }
}

$default-size: 16px;

.mat-mdc-radio-button {
  font-family: theme('fontFamily.primary');

  .mdc-radio {
    --mdc-radio-state-layer-size: #{$default-size};
  }

  .mdc-form-field {
    --mdc-form-field-label-text-tracking: 0;

    label {
      font-family: theme('fontFamily.primary');
      @apply font-normal-exam text-md pb-0 pl-2 text-zinc-600;
      display: flex;
      align-items: center;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &.mat-mdc-radio-checked label {
    @apply text-zinc-800;
  }

  &.mat-primary .mdc-radio--disabled + label {
    @apply text-typography-disabled;
  }

  &.mat-primary .mdc-radio {
    height: $default-size;
    width: $default-size;

    .mat-mdc-radio-touch-target,
    .mdc-radio__background {
      height: 100%;
      width: 100%;
    }

    .mdc-radio__background {
      &::before {
        display: none;
      }

      .mdc-radio__outer-circle {
        border-width: 1px;
        --mdc-radio-unselected-icon-color: var(--gray-400);
        --mdc-radio-selected-icon-color: var(--gray-500);
        --mdc-radio-selected-hover-icon-color: var(--gray-600);
        --mdc-radio-unselected-hover-icon-color: var(--gray-500);
        --mdc-radio-unselected-pressed-icon-color: var(--gray-500);
        --mdc-radio-unselected-focus-icon-color: var(--gray-500);
        --mdc-radio-disabled-unselected-icon-color: var(--gray-200);
        --mdc-radio-disabled-unselected-icon-opacity: 1;
      }

      .mdc-radio__inner-circle {
        border-width: 8px;
        --mdc-radio-selected-icon-color: var(--green-500);
      }
    }
  }
}

.mat-mdc-form-field-error {
  .mdc-radio__outer-circle {
    --mdc-radio-unselected-icon-color: theme('colors.red.700') !important;
  }
}

.radio-group-col {
  display: flex;
  flex-direction: column;

  > .mat-mdc-radio-button:not(.custom-mat-menu-item) {
    @apply mb-1;

    &:last-child {
      @apply mb-0;
    }
  }
}
