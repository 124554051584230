.mat-mdc-slider.mdc-slider {
  $slider-height: 24px;
  height: $slider-height;
  --mdc-slider-inactive-track-height: 8px;
  --mdc-slider-active-track-height: 8px;
  --mdc-slider-inactive-track-color: theme('colors.neutral.100');
  --mdc-slider-active-track-color: theme('colors.sky.400');
  --mdc-slider-handle-color: theme('colors.neutral.0');
  --mdc-slider-focus-handle-color: theme('colors.sky.400');
  --mdc-slider-hover-handle-color: theme('colors.sky.400');
  --mdc-slider-with-tick-marks-inactive-container-color: theme('colors.sky.500');
  --mdc-slider-with-overlap-handle-outline-color: theme('colors.sky.500');
  --mdc-slider-with-overlap-handle-outline-width: 2px;
  width: calc(100% - 8px * 2);

  .mdc-slider__track--inactive {
    opacity: 1;
  }

  .mdc-slider__thumb-knob {
    box-shadow: none;
    border-color: theme('colors.sky.400');
    border-width: 4px;
  }

  .mdc-slider__thumb {
    width: $slider-height;
    height: $slider-height;
    left: calc(-1 * $slider-height / 2);
  }

  .mdc-slider__input {
    height: $slider-height;
  }

  .mdc-slider__value-indicator-container {
    bottom: calc($slider-height + 4px);
  }
}
