.custom-toggle-group {
  font-family: theme('fontFamily.primary');
  --mat-standard-button-toggle-selected-state-background-color: theme('colors.neutral.0');
  --mat-standard-button-toggle-state-layer-color: theme('colors.neutral.200');
  background-color: transparent !important;

  &.mat-button-toggle-group {
    border: 0;
    overflow: visible;
  }

  &.mat-button-toggle-group-appearance-standard
    .mat-button-toggle-appearance-standard
    + .mat-button-toggle-appearance-standard {
    border-left: 0;
  }

  .mat-button-toggle-appearance-standard {
    @apply text-md font-semibold text-neutral-500;

    .mat-button-toggle-label-content {
      @apply p-0;
      line-height: theme('fontSize.md.1');
      z-index: 1;
    }
  }

  .mat-button-toggle {
    &.mat-button-toggle-checked {
      .mat-button-toggle-button {
        color: theme('colors.sky.500');

        &::after {
          background: theme('colors.sky.50');
          border-color: theme('colors.sky.300');
          color: theme('colors.sky.300');
        }
      }
    }

    + .mat-button-toggle .mat-button-toggle-button {
      &::after {
        border-left-width: 0;
      }
    }

    &:has(+ .mat-button-toggle.mat-button-toggle-checked) {
      .mat-button-toggle-button {
        &::after {
          border-right-width: 0;
        }
      }
    }

    + .mat-button-toggle.mat-button-toggle-checked .mat-button-toggle-button {
      &::after {
        border-left-width: 1px;
      }
    }

    &:first-child {
      .mat-button-toggle-button {
        &::after {
          border-top-left-radius: theme('spacing.3');
          border-bottom-left-radius: theme('spacing.3');
        }
      }

      .mat-button-toggle-focus-overlay {
        border-top-left-radius: theme('spacing.3');
        border-bottom-left-radius: theme('spacing.3');
      }
    }

    &:last-child {
      .mat-button-toggle-button {
        &::after {
          border-top-right-radius: theme('spacing.3');
          border-bottom-right-radius: theme('spacing.3');
        }
      }

      .mat-button-toggle-focus-overlay {
        border-top-right-radius: theme('spacing.3');
        border-bottom-right-radius: theme('spacing.3');
      }
    }
  }

  .mat-button-toggle-button {
    --border-width: 1px;
    --border-bottom-width: 4px;
    display: flex;
    justify-content: center;
    border-width: var(--border-width) var(--border-width) var(--border-bottom-width) var(--border-width);
    padding: calc(theme('spacing.4') - #{var(--border-width)}) theme('spacing.4')
      calc(theme('spacing.4') - #{var(--border-bottom-width)});
    border-style: solid;
    border-color: transparent;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: calc(-1 * var(--border-width));
      left: calc(-1 * var(--border-width));
      right: calc(-1 * var(--border-width));
      top: calc(-1 * var(--border-width));
      z-index: 0;
      border: var(--border-width) solid theme('colors.neutral.200');
      box-shadow: 0 calc(var(--border-bottom-width) - var(--border-width)) 0;
      background: theme('colors.neutral.0');
      color: theme('colors.neutral.200');
    }

    &:active {
      $translate: calc(var(--border-bottom-width) - var(--border-width));
      transform: translateY(#{$translate}) translateZ(0);

      &::after {
        box-shadow: none;
      }
    }
  }

  &.small {
    .mat-button-toggle-appearance-standard {
      @apply text-sm;

      .mat-button-toggle-label-content {
        line-height: 20px;
      }
    }

    .mat-button-toggle-button {
      padding: calc(theme('spacing.3') - #{var(--border-width)}) theme('spacing.4')
        calc(theme('spacing.3') - #{var(--border-bottom-width)});
    }
  }
}
