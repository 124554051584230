.custom-mat-accordion.mat-accordion {
  --mat-expansion-header-hover-state-layer-color: theme('colors.neutral.50');
  --mat-expansion-header-focus-state-layer-color: theme('colors.neutral.100');
  --mat-expansion-container-shape: theme('spacing.4');
  --mat-expansion-container-text-tracking: normal;

  @apply gap-4;
  display: flex;
  flex-direction: column;

  .mat-expansion-panel {
    border-radius: theme('spacing.4');
    box-shadow: none;
    border: 1px solid theme('colors.neutral.200');

    &:first-of-type,
    &:last-of-type {
      --mat-expansion-container-shape: theme('spacing.4');
    }

    &:not(.mat-expanded),
    &:not(.mat-expansion-panel-spacing) {
      border-radius: theme('spacing.4');
    }

    &.mat-expanded .mat-expansion-panel-header {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .mat-expansion-panel-header-description {
    white-space: nowrap;
    flex-grow: 0;
    margin-right: theme('spacing.2');
  }

  .mat-expansion-panel-body {
    @apply bg-neutral-50 px-6 py-4;
    border-top: 1px solid var(--neutral-200);
  }

  .mat-expansion-panel-header {
    @apply p-6;
    --mat-expansion-header-text-font: theme('fontFamily.primary');
    --mat-expansion-header-collapsed-state-height: auto;
    --mat-expansion-header-expanded-state-height: auto;
    --mat-expansion-header-text-size: theme('fontSize.md.0');
    --mat-expansion-header-text-weight: theme('fontWeight.medium-exam');
    --mat-expansion-header-text-line-height: theme('fontSize.md.1');
    --mat-expansion-header-text-tracking: normal;

    &[aria-disabled='true'] {
      cursor: default;
    }

    &.mat-expansion-toggle-indicator-after .mat-expansion-indicator {
      transform: rotate(-90deg) !important;
      top: 0;

      &::after {
        @apply p-1;
      }
    }

    &.mat-expanded {
      &.mat-expansion-toggle-indicator-after .mat-expansion-indicator {
        transform: rotate(0deg) !important;
        top: -2px;
        left: 2px;
      }
    }

    .mat-content {
      align-items: center;

      &.mat-content-hide-toggle {
        @apply m-0;
      }
    }

    &,
    .mat-content {
      &:has(.mat-expansion-indicator) .step-title {
        @apply pl-6;
      }
    }
  }

  .mat-expansion-panel-body,
  .mat-expansion-panel-content {
    --mat-expansion-container-text-weight: theme('fontWeight.normal-exam');
  }

  .mat-expansion-panel-header-title {
    @apply text-typography-tertiary;
    overflow-x: hidden;
  }

  .custom-expansion-panel-icon {
    @apply mr-2;
  }

  .mat-expanded {
    > .custom-expansion-panel-icon {
      transform: rotate(180deg);
    }

    > .mat-expansion-indicator {
      top: 2px;

      &::after {
        --mat-expansion-header-indicator-color: var(--typography-primary);
      }
    }
  }

  .mat-expansion-panel-spacing {
    @apply m-0;
  }

  .mat-expansion-indicator {
    @apply ml-4;
    position: relative;
    top: -2px;

    &::after {
      --mat-expansion-header-indicator-color: var(--typography-secondary);
      border-width: 0 1px 1px 0;
      padding: 5px;
      vertical-align: inherit;
    }
  }
}
