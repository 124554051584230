@keyframes animate-warn-background {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes animate-warn-line {
  0% {
    height: 0;
    opacity: 0;
  }

  40% {
    height: 4.8px;
    opacity: 1;
  }

  100% {
    height: 8px;
    opacity: 1;
  }
}

.hot-toast-warning-icon {
  animation: var(
    --hot-toast-warning-icon-animation,
    animate-warn-background 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards
  );
  animation-delay: var(--hot-toast-warning-icon-animation-delay, 100ms);
  background-color: var(--hot-toast-warning-icon-background-color, var(--warn-primary, #ffab00));
  border-radius: var(--hot-toast-warning-icon-border-radius, 50%);
  display: var(--hot-toast-warning-icon-display, block);
  height: var(--hot-toast-warning-icon-height, 20px);
  opacity: var(--hot-toast-warning-icon-opacity, 0);
  position: var(--hot-toast-warning-icon-position, relative);
  transform: var(--hot-toast-warning-icon-transform, scale(0));
  width: var(--hot-toast-warning-icon-width, 20px);

  &::after,
  &::before {
    background-color: var(--hot-toast-warning-icon-after-before-background-color, var(--warn-secondary, #fff));
    border-radius: var(--hot-toast-warning-icon-after-before-border-radius, 3px);
    box-sizing: var(--hot-toast-warning-icon-after-before-box-sizing, border-box);
    content: var(--hot-toast-warning-icon-after-before-content, '');
    display: var(--hot-toast-warning-icon-after-before-display, block);
    left: var(--hot-toast-warning-icon-after-before-left, 8.5px);
    position: var(--hot-toast-warning-icon-after-before-position, absolute);
    width: var(--hot-toast-warning-icon-after-before-width, 2.5px);
  }

  &::after {
    animation: var(--hot-toast-warning-icon-after-animation, animate-warn-line 0.2s ease-out forwards);
    animation-delay: var(--hot-toast-warning-icon-after-animation-delay, 200ms);
    height: var(--hot-toast-warning-icon-after-height, 0);
    opacity: var(--hot-toast-warning-icon-after-opacity, 0);
    top: var(--hot-toast-warning-icon-after-top, 4px);
  }

  &::before {
    bottom: var(--hot-toast-warning-icon-before-bottom, 4px);
    height: var(--hot-toast-warning-icon-before-height, 2px);
  }
}
