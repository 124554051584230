.mat-mdc-progress-bar {
  border-radius: 6.25rem;
  --mdc-linear-progress-active-indicator-color: theme('colors.blue.500');
  --mdc-linear-progress-active-indicator-height: 0.375rem;
  --mdc-linear-progress-track-height: 0.375rem;
  --mdc-linear-progress-track-shape: 6.25rem;
  --mdc-linear-progress-track-color: theme('colors.neutral.200');

  .mdc-linear-progress__bar-inner {
    //border-radius: 6.25rem;//todo Tanya
  }
}
