as-split {
  > .as-split-gutter {
    background-color: transparent !important;
    position: relative;

    &::before {
      background: theme('colors.zinc.200');
      position: absolute;
      content: '';
      z-index: 11;
    }

    &:hover {
      background: theme('colors.zinc.200') !important;
    }

    .as-split-gutter-icon {
      background: none !important;
      position: absolute;
      z-index: 2;
    }
  }

  &.as-horizontal {
    &:not(.as-disabled) > .as-split-gutter {
      .as-split-gutter-icon {
        @apply px-1;
      }

      &::before {
        width: 1px;
        height: 100%;
      }

      &:not(.as-disabled):hover::before {
        @apply px-0.5;
      }
    }
  }

  &.as-vertical {
    > .as-split-gutter {
      .as-split-gutter-icon {
        @apply py-1;
      }

      &::before {
        width: 100%;
        height: 1px;
      }

      &:hover::before {
        @apply py-0.5;
      }
    }
  }
}
