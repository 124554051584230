@keyframes hotToastCircleAnimation {
  from {
    opacity: 0;
    transform: scale(0) rotate(45deg);
  }

  to {
    opacity: 1;
    transform: scale(1) rotate(45deg);
  }
}

@keyframes hotToastCheckmarkAnimation {
  0% {
    height: 0;
    opacity: 0;
    width: 0;
  }

  40% {
    height: 0;
    opacity: 1;
    width: 6px;
  }

  100% {
    height: 10px;
    opacity: 1;
  }
}

.hot-toast-checkmark-icon {
  animation: var(
    --hot-toast-checkmark-icon-animation,
    hotToastCircleAnimation 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards
  );
  animation-delay: var(--hot-toast-checkmark-icon-animation-delay, 100ms);
  background-color: var(--hot-toast-checkmark-icon-background-color, var(--check-primary, #61d345));
  border-radius: var(--hot-toast-checkmark-icon-border-radius, 10px);
  height: var(--hot-toast-checkmark-icon-height, 20px);
  opacity: var(--hot-toast-checkmark-icon-opacity, 0);
  position: var(--hot-toast-checkmark-icon-position, relative);
  transform: var(--hot-toast-checkmark-icon-transform, rotate(45deg));
  width: var(--hot-toast-checkmark-icon-width, 20px);

  @media (prefers-reduced-motion: reduce) {
    animation-duration: var(--hot-toast-checkmark-icon-reduced-motion-animation-duration, 0ms);
  }
}

.hot-toast-checkmark-icon::after {
  animation: var(--hot-toast-checkmark-icon-after-animation, hotToastCheckmarkAnimation 0.2s ease-out forwards);
  animation-delay: var(--hot-toast-checkmark-icon-after-animation-delay, 200ms);
  border-bottom-style: solid;
  border-bottom-width: var(--hot-toast-checkmark-icon-after-border-bottom, 2px);
  border-color: var(--hot-toast-checkmark-icon-after-border-color, var(--check-secondary, #fff));
  border-right-style: solid;
  border-right-width: var(--hot-toast-checkmark-icon-after-border-right, 2px);
  bottom: var(--hot-toast-checkmark-icon-after-bottom, 6px);
  box-sizing: var(--hot-toast-checkmark-icon-after-box-sizing, border-box);
  content: var(--hot-toast-checkmark-icon-after-content, '');
  height: var(--hot-toast-checkmark-icon-after-height, 10px);
  left: var(--hot-toast-checkmark-icon-after-left, 6px);
  opacity: var(--hot-toast-checkmark-icon-after-opacity, 0);
  position: var(--hot-toast-checkmark-icon-after-position, absolute);
  width: var(--hot-toast-checkmark-icon-after-width, 6px);

  @media (prefers-reduced-motion: reduce) {
    animation-duration: var(--hot-toast-checkmark-icon-after-reduced-motion-animation-duration, 0ms);
  }
}
