.mat-mdc-slide-toggle {
  &.mat-primary {
    --mdc-switch-selected-track-color: theme('colors.sky.500');
    --mdc-switch-selected-hover-track-color: theme('colors.sky.500');
    --mdc-switch-selected-pressed-track-color: theme('colors.sky.600');
    --mdc-switch-selected-focus-track-color: theme('colors.sky.600');
    --mdc-switch-selected-handle-color: theme('colors.white');
    --mdc-switch-selected-hover-handle-color: theme('colors.white');
    --mdc-switch-selected-pressed-handle-color: theme('colors.white');
    --mdc-switch-selected-focus-handle-color: theme('colors.white');
    --mdc-switch-selected-focus-state-layer-color: theme('colors.white');

    --mdc-switch-unselected-track-color: theme('colors.neutral.300');
    --mdc-switch-unselected-hover-track-color: theme('colors.neutral.400');
    --mdc-switch-unselected-pressed-track-color: theme('colors.neutral.400');
    --mdc-switch-unselected-focus-track-color: theme('colors.neutral.400');
    --mdc-switch-unselected-handle-color: theme('colors.white');
    --mdc-switch-unselected-hover-handle-color: theme('colors.neutral.50');
    --mdc-switch-unselected-pressed-handle-color: theme('colors.neutral.50');
    --mdc-switch-unselected-focus-handle-color: theme('colors.neutral.50');

    --mdc-switch-disabled-selected-track-color: theme('colors.neutral.400');
    --mdc-switch-disabled-selected-handle-color: theme('colors.neutral.200');
    --mdc-switch-disabled-unselected-track-color: theme('colors.neutral.400');
    --mdc-switch-disabled-unselected-handle-color: theme('colors.neutral.200');

    --mdc-switch-handle-surface-color: transparent;
    --mdc-switch-handle-elevation-shadow: none;
    --mdc-form-field-label-text-tracking: normal;

    .dark & {
      --mdc-switch-selected-track-color: var(--primary-400);
      --mdc-switch-selected-hover-track-color: var(--primary-400);
      --mdc-switch-selected-pressed-track-color: var(--primary-400);
      --mdc-switch-selected-focus-track-color: var(--primary-400);
    }
  }

  .mdc-form-field--align-end > label {
    padding-right: theme('spacing.2');
  }

  .mdc-switch__handle::before,
  .mdc-switch__handle::after {
    z-index: 0;
  }

  .mdc-switch {
    --mdc-switch-track-width: 62px;
    --mdc-switch-track-height: 32px;
    --mdc-switch-track-shape: 10px;

    --mdc-switch-handle-width: 28px;
    --mdc-switch-handle-height: 28px;
    --mdc-switch-handle-shape: theme('spacing.2');

    --mdc-switch-state-layer-size: 28px;

    &.mdc-switch--selected .mdc-switch__handle-track {
      transform: translateX(30px);
    }

    &.mdc-switch--selected:enabled,
    &.mdc-switch--unselected:enabled,
    &.mdc-switch--disabled {
      .mdc-switch__icons {
        display: none;
      }
    }

    .mdc-switch__handle {
      left: 2px;
    }
  }

  &.small {
    .mdc-switch {
      --mdc-switch-track-width: 46px;
      --mdc-switch-track-height: 24px;
      --mdc-switch-track-shape: 8px;

      --mdc-switch-handle-width: 20px;
      --mdc-switch-handle-height: 20px;
      --mdc-switch-handle-shape: theme('spacing[1.5]');

      --mdc-switch-state-layer-size: 20px;

      &.mdc-switch--selected .mdc-switch__handle-track {
        transform: translateX(22px);
      }
    }
  }

  &.full-row {
    .mdc-form-field {
      width: 100%;
      justify-content: space-between;
    }

    .mdc-form-field--align-end > label {
      margin-left: 0;
    }
  }

  label {
    @apply text-md;

    &:empty {
      padding-left: 0;
    }
  }
}
