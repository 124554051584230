.mat-mdc-checkbox {
  &.mat-primary {
    --mdc-checkbox-unselected-focus-state-layer-color: var(--sky-400);
    --mdc-checkbox-unselected-hover-state-layer-color: var(--sky-400);
    --mdc-checkbox-unselected-pressed-state-layer-color: var(--sky-400);
    --mdc-checkbox-selected-focus-state-layer-color: var(--sky-400);
    --mdc-checkbox-selected-hover-state-layer-color: var(--sky-400);
    --mdc-checkbox-selected-pressed-state-layer-color: var(--sky-400);
  }

  .mdc-form-field {
    > label {
      font-family: theme('fontFamily.primary');
      @apply font-normal-exam text-md pb-0 pl-2 text-zinc-600;
      letter-spacing: 0;

      .dark & {
        @apply font-normal-dark;
      }

      > * {
        @apply mr-1;

        &:last-child {
          @apply mr-0;
        }
      }
    }
  }

  .mdc-form-field--align-end {
    width: 100%;

    &.mdc-form-field > label {
      @apply pl-0 pr-2;
      width: 100%;
    }
  }

  .mat-mdc-checkbox-ripple,
  .mdc-checkbox__ripple {
    border-radius: 4px;
  }

  &-checked {
    .mdc-form-field > label {
      @apply text-zinc-800;
    }
  }

  &-disabled {
    .mdc-form-field > label {
      @apply text-typography-disabled;
    }
  }

  .mdc-checkbox {
    --mdc-checkbox-state-layer-size: 26px;

    &:hover
      .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate='true'])
      ~ .mdc-checkbox__background {
      background: theme('colors.blue.50');
    }

    .mat-mdc-checkbox-touch-target {
      height: 100%;
      width: 100%;
    }

    .mdc-checkbox__checkmark {
      width: 10px;
      height: 10px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      --mdc-checkbox-selected-checkmark-color: var(--white);
    }

    .mdc-checkbox__background {
      width: 18px;
      height: 18px;
      border-radius: 4px;
      border-width: 1px;
    }
  }
}

.mdc-checkbox__background {
  --mdc-checkbox-unselected-icon-color: var(--neutral-200);
  --mdc-checkbox-selected-hover-icon-color: var(--sky-400);
  --mdc-checkbox-unselected-focus-icon-color: var(--sky-400);
  --mdc-checkbox-selected-pressed-icon-color: var(--sky-500);
  --mdc-checkbox-disabled-unselected-icon-color: var(--neutral-200);
  --mdc-checkbox-unselected-pressed-icon-color: var(--sky-400);
  --mdc-checkbox-selected-icon-color: var(--sky-400);
  --mdc-checkbox-selected-focus-icon-color: var(--sky-400);
  --mdc-checkbox-unselected-hover-icon-color: var(--sky-400);

  .dark & {
    --mdc-checkbox-unselected-hover-icon-color: var(--gray-500);
    --mdc-checkbox-selected-icon-color: var(--primary-400);
    --mdc-checkbox-selected-focus-icon-color: var(--primary-400);
    --mdc-checkbox-selected-hover-icon-color: var(--primary-500);
  }

  .ng-touched.ng-invalid .mat-mdc-checkbox[required] & {
    --mdc-checkbox-unselected-icon-color: var(--red-600);
  }
}

.checkbox-group-col {
  display: flex;
  flex-direction: column;

  > .mat-mdc-checkbox {
    @apply mb-1;

    &:last-child {
      @apply mb-0;
    }
  }
}

.checkbox-group-row {
  > .mat-mdc-checkbox {
    @apply mr-4;

    &:last-child {
      @apply mr-0;
    }
  }
}
