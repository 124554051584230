@use 'sass:map';
@use 'mixins' as *;

$no-label-padding-compact: (
  'top': theme('spacing.1'),
  'bottom': theme('spacing.1'),
);

$no-label-padding: (
  'top': calc(theme('spacing.2') + 1px),
  //border width
  'bottom': calc(theme('spacing.2') + 1px),
);
$with-label-padding: (
  'top': theme('spacing.6'),
  'bottom': theme('spacing.2'),
);

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: map.get($with-label-padding, top);
  padding-bottom: map.get($with-label-padding, bottom);

  .compact-field.custom-mat-form-wrapper & {
    padding-top: map.get($no-label-padding-compact, top);
    padding-bottom: map.get($no-label-padding-compact, bottom);
  }
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: map.get($no-label-padding, top);
  padding-bottom: map.get($no-label-padding, bottom);
}

.mdc-line-ripple {
  &::before,
  &::after {
    display: none;
  }
}
.mat-mdc-form-field {
  //height: 44px; //todo
  --mat-form-field-container-text-weight: 400;
  --mdc-filled-text-field-input-text-color: theme('colors.primary-exam');
  --mat-form-field-container-text-size: 16px;
  --mat-form-field-container-text-line-height: 24px;
  --mdc-filled-text-field-disabled-container-color: theme('colors.neutral.100');
  font-family: theme('fontFamily.primary');

  @apply text-md;
  position: relative;

  .dark & {
    --mat-form-field-container-text-weight: 400;
  }

  &-text-prefix,
  &-text-suffix,
  &-icon-prefix,
  &-icon-suffix {
    color: theme('colors.primary-exam');

    .mdc-text-field--disabled & {
      color: theme('colors.disabled');
    }

    &:empty {
      padding: 0;
    }
  }

  .mdc-text-field {
    border: 1px solid theme('colors.neutral.200');
    border-radius: theme('spacing.3');

    &--invalid {
      border-color: theme('colors.red.600');
    }

    &--filled:not(.mdc-text-field--disabled) {
      .mdc-line-ripple::before {
        display: none;
      }
    }
  }

  &:hover,
  &.mat-focused {
    .mat-mdc-form-field-focus-overlay {
      opacity: 0;

      .dark & {
        opacity: 0;
      }
    }
  }

  .mat-mdc-form-field-infix {
    min-height: auto;
  }

  .time-field.custom-mat-form-wrapper & {
    width: 100%;
  }
}

.mdc-text-field--filled.mat-form-field-disabled,
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background: none;

  .dark & {
    background: none;
  }
}

.mdc-text-field--disabled.mdc-text-field--filled {
  background: theme('colors.neutral.100');
  border-color: theme('colors.neutral.200');

  .dark & {
    background: theme('colors.neutral.100');
    border-color: theme('colors.neutral.200');
  }
}

.custom-mat-form-wrapper {
  --mdc-filled-text-field-container-color: transparent;
  --mdc-filled-text-field-disabled-container-color: transparent;
  --mat-form-field-state-layer-color: transparent;

  .mat-mdc-form-field-subscript-wrapper {
    position: static;
  }

  .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field-error-wrapper {
    position: static;
  }

  .mat-mdc-form-field-hint {
    @apply text-disabled;
  }

  .mat-mdc-form-field-bottom-align {
    font-size: theme('fontSize.sm.0');
    line-height: 1;
    letter-spacing: 0;

    &::before {
      display: none;
    }

    &.mat-mdc-form-field-subscript-dynamic-size::after {
      display: none;
    }

    .mat-mdc-form-field-hint-wrapper,
    .mat-mdc-form-field-error-wrapper {
      @apply px-0;
      font-weight: 500;

      .mat-mdc-form-field-error,
      .mat-mdc-form-field-hint {
        margin-top: theme('spacing.2');
      }

      .mat-mdc-form-field-error {
        &::before {
          display: inline-flex;
          content: '\f071';
          font-family: 'Font Awesome 6 Pro';
          font-size: 16px;
          margin-right: theme('spacing.1');
        }
      }
    }
  }
}

.custom-mat-form-wrapper .mat-form-label:not(:empty) {
  @apply text-md mb-2 gap-1;
  color: theme('colors.primary-exam');
  display: flex;
  align-items: center;
  font-weight: 600;

  &.custom-optional {
    &:after {
      content: '(Optional)';
      font-size: 10px;
      color: theme('colors.primary-exam');
    }
  }

  &:has(+ .mat-form-field-disabled) {
    color: theme('colors.disabled');
  }
}

.custom-mat-form-wrapper {
  .mat-mdc-form-field-error {
    &::before {
      display: none;
    }
  }

  .mdc-label {
    .mat-form-label:not(:empty) {
      margin-bottom: theme('spacing.0');
    }
  }
}

//appearance="fill"
