.custom-mat-form-wrapper {
  &.date-picker-wrapper {
    .mat-datepicker-toggle {
      .mdc-icon-button.mat-mdc-icon-button.mat-mdc-button-base.mat-unthemed {
        --mdc-icon-button-state-layer-size: 24px;
        max-width: 24px;
        max-height: 24px;
      }
    }
  }
}

.mat-calendar {
  --mat-datepicker-calendar-text-font: theme('fontFamily.primary');
  --mat-datepicker-calendar-text-size: theme('fontSize.sm.0');
  --mat-datepicker-calendar-date-selected-state-text-color: theme('colors.neutral.0');
  --mat-datepicker-calendar-date-selected-state-background-color: theme('colors.sky.500');
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: var(--gray-500);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: theme('colors.sky.500');
  --mat-datepicker-calendar-date-focus-state-background-color: theme('colors.neutral.50');
  --mat-datepicker-calendar-date-hover-state-background-color: theme('colors.neutral.50');
  --mat-datepicker-toggle-active-state-icon-color: theme('colors.sky.500');
  --mat-datepicker-calendar-date-today-outline-color: theme('colors.sky.500');
  --mat-datepicker-calendar-date-text-color: theme('colors.secondary');
  --mdc-text-button-label-text-color: theme('colors.secondary');

  .mat-calendar-body-active {
    color: theme('colors.neutral.0');
  }

  .mat-calendar-controls {
    width: 100%;
    justify-content: space-between;
    padding: 0 theme('spacing.2');
    margin-top: 0;

    .mat-calendar-spacer {
      display: none;
    }

    .mat-calendar-period-button {
      order: 2;
      font-weight: 600;
    }

    .mat-calendar-previous-button {
      order: 1;
    }

    .mat-calendar-next-button {
      order: 3;
    }
  }

  .mat-calendar-body-cell-content {
    font-weight: 500;
  }
}

mat-datepicker-content.mat-datepicker-content {
  --mat-datepicker-calendar-header-text-color: theme('colors.disabled');
  --mat-datepicker-calendar-header-text-weight: 500;
  box-shadow: none;
  border-radius: 16px;
  border: 1px solid theme('colors.neutral.200');

  .mat-calendar-table-header-divider::after {
    display: none;
  }

  .mat-datepicker-close-button {
    display: none;
  }
}
