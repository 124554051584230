$main-menu-devices-height: 55px;
$schedule-box-height: 475px;
$menu-icon-wrapper-size: 14px;
$menu-icon-wrapper-size-big: 16px;
$menu-icon-size: 12px;
$scrollbar-width: 8px;
$job-max-width-content: 1420px;
$job-popover-max-width: 350px;
$ag-grid-min-height: 110px;
$ag-grid-data-catalog-min-height: 300px;

$opacity-level-1: 0.94;
$opacity-level-2: 0.64;
$opacity-level-3: 0.32;

$top-nav-min-height: 24px;
$tabbed-view-tabs-min-height: 35px;

$content-max-width: 1024px;
$small-content-max-width: 660px;
$tiny-content-max-width: 480px;
$container-padding-lg: 32px;
$container-padding-sm: 16px;

//Data-catalog elements' sizes
$min-height-inner-row: 110px;
$max-height-inner-row: 500px;
$toolbar-grid-height-big: 42px;
$toolbar-grid-height-small: 26px;
$toolbar-grid-height-tabs: 30px;
$toolbar-grid-height-tabs: 30px;
$data-catalog-section-title-height: calc(theme('fontSize.title-m.1') + theme('spacing.3'));

// If you change this make sure to change SIDEBAR_WIDTH_PX and MIN_SIDEBAR_WIDTH_PX in `asset-editor-view.component.ts`
$asset-editor-sidebar-width: 360px;
$asset-editor-min-width: 10px;

// If you change this make sure to change MIN_DRAWER_HEIGHT_PX in `bottom-drawer.component.ts` and shell-bottom-drawer.component.ts as well
$bottom-drawer-toolbar-height: 35px;
$bottom-drawer-min-height: #{$bottom-drawer-toolbar-height * 2};
$drag-target: 1px;

//Dialog sizes
$widthSmall: 600px;
$widthMedium: 800px;
$widthLarge: 1000px;
$widthHuge: 80vw;
$widthResizeSize: 80vw;
$heightMedium: 500px;
$heightLarge: 550px;
$heightHuge: 650px;
$heightResizeSize: 90vh;

//Dialog breakpoints and helpers
$responsiveYOffset: 20px;
$heightMediumBreakpoint: calc(#{$heightMedium} + #{$responsiveYOffset});
$heightLargeBreakpoint: calc(#{$heightLarge} + #{$responsiveYOffset});
$heightHugeBreakpoint: calc(#{$heightHuge} + #{$responsiveYOffset});

//Switch toggle component sizes
$switch-toggle-track-width: 32px;
$switch-toggle-track-height: 16px;
$switch-toggle-track-border-radius: 33px;
$switch-toggle-handle-size: 12px;
$switch-toggle-handle-border-radius: 50%;
$switch-toggle-handle-offset: 2px;

//Lineage floating elements position
$topFloatOffset: theme('spacing.2');
$leftFloatOffset: theme('spacing.5');

//Asset tree helpers
$asset-tree-row-height: 33px; //If you change it, please change 'rowHeight' in asset-tree.component.ts
$asset-tree-icon-width: 16px;
$node-level-offset: calc(#{$asset-tree-icon-width} / 2);
$node-content-padding: theme('spacing[2]');
$focus-outline-width: 1px;
$right-icon-margin: theme('spacing.1');
$offset-gap: 16px;
$node-horizontal-line: calc($offset-gap / 2);
