@import '@fortawesome/fontawesome-pro/css/all.css';

@font-face {
  font-family: 'inter';
  src: url('../assets/fonts/inter-light.woff2');
  font-weight: 300;
}

@font-face {
  font-family: 'inter';
  src: url('../assets/fonts/inter-regular.woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'inter';
  src: url('../assets/fonts/inter-medium.woff2');
  font-weight: 500;
}

@font-face {
  font-family: 'inter';
  src: url('../assets/fonts/inter-semibold.woff2');
  font-weight: 600;
}

@font-face {
  font-family: 'inter';
  src: url('../assets/fonts/inter-bold.woff2');
  font-weight: 700;
}
