.mdc-text-field {
  &:not(.mdc-text-field--disabled) .mdc-text-field__input {
    @apply text-typography-primary;
    caret-color: var(--primary-600);

    .dark & {
      @apply text-typography-primary;
      caret-color: var(--primary-600);
    }

    &::placeholder {
      @apply text-body-m;
      --mdc-filled-text-field-input-text-placeholder-color: var(--typography-disabled);
    }
  }

  &.mdc-text-field--disabled .mdc-text-field__input {
    @apply text-typography-disabled;

    .dark & {
      @apply text-typography-disabled;
    }

    &::placeholder {
      --mdc-filled-text-field-input-text-placeholder-color: var(--typography-disabled);
    }
  }
}

//mat-chip-grid to be removed from here and added to the its own style file
.mat-mdc-form-field-type-mat-input,
.mat-mdc-form-field-type-mat-chip-grid {
  &:hover {
    .mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) {
      border-color: var(--gray-500);

      .dark & {
        border-color: var(--gray-500);
      }
    }
  }

  .mdc-text-field {
    &--filled:not(.mdc-text-field--disabled) {
      background: transparent;

      .dark & {
        background: transparent;
      }
    }
  }

  &.mat-focused {
    .mdc-text-field:not(.mdc-text-field--disabled) {
      border-color: var(--primary-600);

      .dark & {
        border-color: var(--primary-600);
      }
    }
  }
}

.mat-mdc-form-field-has-icon-suffix,
.mat-mdc-form-field-has-icon-prefix {
  y42-avatar,
  dojo-icon,
  fa-icon {
    svg {
      width: 14px;
      height: 14px;
    }
  }
}

.mat-mdc-text-field-wrapper {
  transition: border-color 0.25s ease-in;

  .mat-mdc-form-field-has-icon-prefix.mat-mdc-form-field & {
    @apply pl-3;
    --mat-mdc-form-field-label-offset-x: 0;
  }

  .mat-mdc-form-field-has-icon-suffix.mat-mdc-form-field & {
    @apply pr-3;
  }
}

//autofill hack https://css-tricks.com/almanac/selectors/a/autofill/
//it should fix the size but it doesn't input:-webkit-autofill::first-line
input.mdc-text-field__input:-webkit-autofill {
  -webkit-text-fill-color: var(--typography-primary);
  -webkit-box-shadow: 0 0 0 40rem var(--gray-200) inset !important;

  &:hover,
  &:focus {
    -webkit-text-fill-color: var(--typography-primary);
    -webkit-box-shadow: 0 0 0 40rem var(--gray-200) inset !important;
  }
}

.custom-input-number .mat-mdc-form-field-infix {
  &:hover {
    &::after,
    &::before {
      opacity: 1;
    }
  }

  &::after,
  &::before {
    @apply text-typography-secondary bg-gray-300;
    position: absolute;
    right: 0;
    width: 16px;
    height: 8px;
    font: var(--fa-font-regular);
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
  }

  &::before {
    content: '\f106';
    top: 7px;
  }

  &::after {
    content: '\f107';
    bottom: 8px;
  }

  input[type='number']::-webkit-inner-spin-button {
    opacity: 0;
  }
}

.full-width-field .mat-mdc-form-field-infix {
  width: 100%;
}
