@use 'mixins' as *;
@use 'breakpoints-mixins' as *;

html {
  --ion-default-font: theme('fontFamily.primary');

  &.ios {
    --ion-default-font: theme('fontFamily.primary');
  }

  &.md {
    --ion-default-font: theme('fontFamily.primary');
  }
}

.mobile-header {
  @apply shadow-none;
  border-bottom: 1px solid theme('colors.neutral.200');

  &.md {
    border-bottom: 1px solid theme('colors.neutral.200');
  }

  ion-toolbar {
    @apply py-0 #{!important};
    --min-height: 52px;
  }
}

ion-toolbar {
  --padding-start: 0;
  --padding-end: 0;
  --min-height: 64px;
  --background: theme('colors.neutral.0');

  @include md-breakpoint {
    --min-height: 52px;
  }

  &.toolbar-with-closing-button {
    --padding-start: 0;
    --padding-end: 0;

    @include start-from-small {
      ion-title {
        order: 3;
      }

      div[slot='start'] {
        order: 5;
      }

      div[slot='end'] {
        order: 1;
      }
    }
  }

  &.wavy-toolbar {
    --background: theme('colors.purple.500');
    --opacity: 1;
    --border-width: 0;

    @include md-breakpoint {
      margin-top: 0 !important;
    }

    &:last-of-type {
      --border-width: 0;
    }
  }

  ion-title {
    padding-inline: 50px;
  }
}

.wave {
  @apply absolute -bottom-[11px] w-full;
  height: 12px;
  background: url('/assets/images/home/wave.svg') 0 0 repeat-x;
}

dojo-icon-button.wavy-toolbar-close {
  .ion-icon-button {
    &.button-solid {
      --color: white;

      &:hover {
        --border-color: theme('colors.purple.400');
        --background: theme('colors.purple.400');
      }

      &.ion-activated,
      &.ion-focused {
        &:hover {
          --background: theme('colors.purple.400');
          --border-color: theme('colors.purple.400');
        }
      }
    }
  }
}

ion-content::part(scroll) {
  scrollbar-width: thin;
  scrollbar-color: var(--gray-200) transparent;

  &:hover,
  &:active {
    scrollbar-color: var(--gray-300) transparent;
  }
}

ion-modal {
  --backdrop-opacity: 0.5;
  --ion-backdrop-color: #000;

  @media only screen and (min-width: 768px) and (min-height: 600px) {
    --box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    --border-radius: 1.25rem;
  }

  &.modal-default.show-modal ~ ion-modal.modal-default {
    --backdrop-opacity: 0.5 !important;
    --box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1) !important;
  }

  &.modified-modal {
    @media only screen and (min-width: 800px) {
      --width: 700px;
    }

    @media only screen and (min-width: 1000px) {
      --width: 900px;
    }

    @media only screen and (min-width: 1200px) {
      --width: 1000px;
    }
  }

  &.modal-sheet {
    --border-radius: 1.25rem;

    &::part(content) {
      @include md-breakpoint {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &:not(.fullscreen) ion-toolbar {
      @include md-breakpoint {
        margin-top: 16px;
      }
    }
  }

  &.modal-default.action-sheet-on-mobile {
    //emulate mobile action-sheet behaviour for resizing from desktop to mobile
    @include md-breakpoint {
      align-items: flex-end;
    }

    &:not(.auto-height):not(.fullscreen) {
      @include md-breakpoint {
        --height: calc(100% - (var(--ion-safe-area-top) + 10px));
      }
    }

    &:not(.fullscreen) {
      &::part(content) {
        @include md-breakpoint {
          border-top-left-radius: 1.25rem;
          border-top-right-radius: 1.25rem;
        }
      }
    }
  }

  &.auto-height {
    --height: auto;
  }

  &.fullscreen {
    --height: 100vh;
    --width: 100vw;
    --border-radius: 0;

    @include md-breakpoint {
      --height: 100dvh;
    }

    @media screen and (max-height: 400px) {
      --height: 100dvh;
    }
  }

  &:has(.ion-page .compact) {
    --height: auto;
  }

  &::part(handle) {
    width: 40px;

    &::before {
      width: 40px;
    }
  }
}

ion-header {
  box-shadow: none !important;

  &.md {
    border-bottom: 1px solid theme('colors.neutral.200');

    @include md-breakpoint {
      border-bottom: 0;

      ion-toolbar {
        --border-width: 0;
      }
    }
  }

  &.header-ios ion-toolbar:last-of-type {
    --border-width: 0;
  }
}
