@use 'variables' as *;
@use 'breakpoints-mixins' as *;

// If you change this make sure to change COMPACT_MODE_WIDTH_BREAKPOINT in `asset-editor.service.ts`
$detail-view-compact-breakpoint: 1010px;

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin border($border-color: var(--gray-200), $border-width: 1px) {
  border: $border-width solid $border-color;
}

@mixin title-level($title: text-title-s) {
  :host &,
  & {
    @apply #{$title} dark:font-medium-dark font-medium;
  }
}

//mixin for ion small buttons with different colors
@mixin button-styles($color, $bg, $hover-bg, $focus-outline, $border-color: transparent, $border-radius: 6.25rem) {
  font-size: 0.875rem;
  text-transform: none;
  text-align: center;
  letter-spacing: 0;
  margin: 0 0 0 auto;
  font-style: normal;
  font-weight: 500;
  position: relative;
  min-height: auto;

  --border-radius: #{$border-radius};
  --border-color: #{$border-color};
  --border-style: solid;
  --border-width: 0.0625rem;
  --background-focused-opacity: 1;
  --background-hover-opacity: 1;
  --color: #{$color};
  --background: #{$bg};
  --background-hover: #{$hover-bg};
  --background-activated: transparent;
  --background-focused: transparent;
  --ripple-color: #{$bg};
  --box-shadow: none;

  --padding-top: calc(theme('spacing.1') - var(--border-width));
  --padding-bottom: calc(theme('spacing.1') - var(--border-width));

  &::part(native) {
    min-height: auto;
    line-height: 1.5rem;
    padding-right: calc(theme('spacing.3') - var(--border-width));
    padding-left: calc(theme('spacing.3') - var(--border-width));
    white-space: nowrap;
  }

  &:hover {
    --background: #{$hover-bg};
    --border-color: #{$hover-bg};
  }

  &.ion-focused {
    &::part(native) {
      outline: 2px solid #{$focus-outline};
      outline-offset: -2px;
    }
  }

  &.ion-activated,
  &.ion-focused {
    &:hover {
      --background: #{$bg};
    }
  }
}

//UX2 breakpoints
@mixin mobiles {
  @media screen and (max-width: 767px) {
    @content;
  }
}

@mixin devices {
  @media screen and (max-width: 1024px) {
    @content;
  }
}

@mixin asset-editor-compact-view {
  @container asset-editor-container (max-width: #{$detail-view-compact-breakpoint}) {
    @content;
  }
}

@mixin smaller-height-asset-editor {
  @container asset-editor-container (max-height: 500px) {
    @content;
  }
}

@mixin smaller-asset-editor {
  @container asset-editor-container (max-width: #{$detail-view-compact-breakpoint}) and (max-height: 500px) {
    @content;
  }
}

//job mixins
@mixin job-history-content {
  max-width: $job-max-width-content;
  margin-left: auto;
  margin-right: auto;
}

@mixin job-statuses-bar {
  display: flex;
  align-items: flex-end;
  place-content: space-between;
}

@mixin job-statuses-bar-item($size: 16px) {
  min-width: $size;
  height: $size;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1px;
  overflow: hidden;
}

@mixin job-latest-valid() {
  @apply text-typography-primary text-body-s bg-green-400 p-0;

  &.stale {
    @apply bg-yellow-300;

    .label-text {
      @apply text-yellow-1100;
    }
  }
}

@mixin set-grid-columns($columnCount, $gap, $width: 1fr) {
  display: grid;
  gap: $gap;
  grid-template-columns: repeat($columnCount, $width);

  @include mobiles {
    grid-template-columns: repeat(1, 1fr);
  }
}

@mixin font-main-navigation-size {
  font-size: 0.8rem;
  line-height: 0.825rem;
}

//UX3
@mixin button-padding-set($y: theme('spacing.1'), $x: theme('spacing.1')) {
  padding: $y $x;
}

@mixin button-hover-set($color, $dark-color: $color) {
  --mat-text-button-state-layer-color: #{$color};
  --mat-filled-button-state-layer-color: #{$color};

  --mat-text-button-ripple-color: rgba(#{$color}, 0.1);
  --mat-filled-button-ripple-color: rgba(#{$color}, 0.1);

  ::ng-deep .dark & {
    --mat-text-button-state-layer-color: #{$dark-color};
    --mat-filled-button-state-layer-color: #{$dark-color};

    --mat-text-button-ripple-color: rgba(#{$dark-color}, 0.1);
    --mat-filled-button-ripple-color: rgba(#{$dark-color}, 0.1);
  }
}

//Sources config UX3
@mixin sources-config-step {
  .source-config-body {
    @apply text-typography-secondary;
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .source-config-title {
    @apply text-typography-secondary mb-4;
  }

  .source-config-footer {
    @apply p-2;
    border-top: 1px solid var(--gray-200);
    display: flex;
    justify-content: flex-end;
  }
}

@mixin tag-mixin(
  $icon-size: 14px,
  $action-icon-size: 12px,
  $gap: theme('spacing.1'),
  $font: theme('fontFamily.primary'),
  $fontSize: theme('fontSize.body-m.0'),
  $line-height: theme('fontSize.body-m.1'),
  $cursor: default
) {
  @apply rounded-standard bg-gray-200 p-1;
  gap: $gap;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: fit-content;
  font-family: $font;
  font-size: $fontSize;
  line-height: $line-height;

  .tag-icon {
    @apply text-gray-500;
    width: $icon-size;
    height: $icon-size;
    flex-shrink: 0;

    ::ng-deep svg {
      width: 100%;
      height: auto;
    }
  }

  .tag-icon-img {
    max-width: $icon-size;
    max-height: $icon-size;
    width: 100%;
    height: 100%;
  }

  ::ng-deep .button-action {
    @apply right-1;
    display: none;
    max-width: $action-icon-size;
    max-height: $action-icon-size;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    .mdc-icon-button {
      --mdc-icon-button-state-layer-size: #{$action-icon-size};
      max-width: $action-icon-size;
      max-height: $action-icon-size;
    }

    .material-icons {
      font-size: $action-icon-size;
    }
  }

  &:hover {
    cursor: $cursor;

    ::ng-deep {
      .name-value {
        width: calc(100% - #{$action-icon-size} - #{$icon-size} - #{$gap});
      }

      .button-action {
        display: flex;
      }
    }
  }

  &.no-image:hover {
    ::ng-deep .name-value {
      width: calc(100% - #{$action-icon-size});
    }
  }
}

@mixin commit-icon-panel() {
  .commit-panel {
    @apply dark:font-normal-dark text-body-s font-normal;
    display: flex;
    align-items: center;
    cursor: pointer;

    .commit-icon {
      @apply rounded-standard border-yellow-400 bg-yellow-400 text-white dark:border-yellow-700 dark:bg-yellow-600;
      border-width: 1px;
      border-style: solid;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1px;

      &:active,
      &:hover {
        @apply bg-yellow-500 dark:bg-yellow-700;
      }
    }

    .commit-text {
      @apply text-yellow-700;
    }

    ::ng-deep .mdc-button__label {
      @apply gap-1;
      display: flex;
    }

    &.mdc-button.mat-mdc-button-base {
      @include button-hover-set(var(--gray-300));

      &:hover {
        border-color: transparent;
      }
    }
  }

  .panel-mode .commit-panel {
    &:hover {
      .commit-icon {
        @apply bg-yellow-500 dark:bg-yellow-700;
      }
    }
  }
}

@mixin danger-mat-select-footer() {
  .mat-mdc-option:last-of-type {
    @apply -mb-1;
    border-top: 1px solid var(--gray-200);
    padding-top: 1px;
    margin-top: 1px;

    .mdc-list-item__primary-text,
    .mat-mdc-menu-item-text {
      @apply text-typography-danger-primary;
    }
  }
}

@mixin markdown-editor() {
  @apply text-body-m-paragraph;

  p {
    @apply mb-0;
  }

  a {
    @apply text-typography-primary;
    position: relative;
    border-bottom: 1px dashed currentColor;

    &:hover {
      @apply text-typography-primary;
      border-style: solid;
    }
  }

  ul,
  ol {
    @apply pl-6;
  }

  ol {
    list-style: decimal;
  }

  blockquote {
    border-left: 2px solid var(--gray-300);
    padding-left: 10px;
  }

  code {
    @apply font-secondary text-code-m text-typography-primary;
  }

  strong {
    @apply dark:font-medium-dark font-medium;
  }

  h3 {
    @include title-level(text-title-m);
  }
}

@mixin build-summary-table(
  $background: theme('colors.background.tertiary'),
  $padding-left: theme('spacing.3'),
  $padding-right: theme('spacing.3'),
  $padding-top: theme('spacing.1'),
  $padding-bottom: theme('spacing.1')
) {
  background: $background;
  padding: $padding-top $padding-right $padding-bottom $padding-left;

  .build-summary-table {
    @apply gap-x-8;
    position: relative;
    display: grid;
    grid-auto-rows: minmax(32px, auto);

    @include start-from-medium {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .build-summary-table-item {
    @apply text-title-s text-typography-primary gap-3 font-normal;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;

    .dark :host & {
      @apply font-normal-dark;
    }

    &:not(:last-child) {
      @include before-medium {
        border-bottom: 1px solid var(--gray-300);
      }
    }

    &:nth-child(even):not(:last-child),
    &:nth-child(odd):not(:nth-last-child(2)):not(:last-child) {
      @include start-from-medium {
        border-bottom: 1px solid var(--gray-300);
      }
    }

    .item-title {
      @apply text-title-xs text-typography-secondary font-normal;

      .dark & {
        @apply font-normal-dark;
      }
    }

    .open-item {
      @apply gap-1;
      display: flex;
      align-items: center;
      overflow: hidden;
    }
  }
}

@mixin filters-buttons() {
  display: flex;
  align-items: center;

  .filters-buttons-item {
    @apply mr-2;

    &:first-child {
      @apply mr-4;
      position: relative;

      &::after {
        @apply bg-gray-200;
        position: absolute;
        right: -9px;
        height: 100%;
        width: 1px;
        content: '';
      }
    }

    ::ng-deep button.filters-buttons-item.mdc-button {
      @apply rounded-medium text-body-s;
      @include button-padding-set(theme('spacing.1'), theme('spacing.3'));

      .mdc-button__label {
        @apply gap-1;
      }

      .label {
        @apply text-typography-secondary;
      }

      .number {
        @apply text-body-m ml-1;
      }
    }
  }
}

@mixin code-mono-inline(
  $background: var(--background-primary),
  $border-color: var(--gray-300),
  $border-color-dark: var(--gray-200),
  $is-gray-bg: false,
  $is-root-bg: false
) {
  $background-severity: var(--red-200);
  $border-severity: var(--red-300);
  $background-warn: var(--yellow-200);
  $border-warn: var(--yellow-300);
  $background-success: var(--green-200);
  $border-success: var(--green-300);
  $background-info: var(--gray-100);
  $border-info: var(--gray-300);

  $padding-y: 1px;

  @apply font-secondary text-code-s rounded-standard px-1;
  border: 1px solid;
  color: currentColor;
  padding-top: $padding-y;
  padding-bottom: $padding-y;

  @if ($is-gray-bg) {
    background: $background;
    border-color: $border-color;

    .dark & {
      border-color: $border-color-dark;
    }
  } @else if ($is-root-bg) {
    @apply border-gray-300;
    background: var(--background-secondary);

    .dark & {
      border-color: $border-color-dark;
    }
  }

  //the same classes as in info-box
  .severity-error & {
    background: $background-severity;
    border-color: $border-severity;
  }

  .severity-warn & {
    background: $background-warn;
    border-color: $border-warn;
  }

  .severity-success & {
    background: $background-success;
    border-color: $border-success;
  }

  .severity-info & {
    background: $background-info;
    border-color: $border-info;
  }

  //no styles inside buttons and data catalog top nav
  .mdc-button:not(.drawer-tabs-button) &,
  .top-nav-data-catalog & {
    @apply p-0;
    border: 0;
    font: inherit;
    color: inherit;
    background: none;
  }
}

@mixin text-color-theme($color, $darkColor: $color) {
  @apply #{$color};

  ::ng-deep .dark & {
    @apply #{$darkColor};
  }
}

@mixin table-view-assessment() {
  @apply p-4 md:p-6;
  border: 1px solid theme('colors.neutral.200');
  border-top: none;

  &.has-top-border {
    border-top: 1px solid theme('colors.neutral.200');
    border-radius: 16px 16px 0 0;
  }

  &.has-bottom-border {
    border-radius: 0 0 16px 16px;

    &.has-top-border {
      border-radius: 16px;
    }
  }
}

@mixin assessment-topic-circle() {
  @apply text-neutral-0 flex h-[40px] w-[40px] items-center justify-center rounded-full;
  border: 2px solid theme('colors.neutral.0');
  min-width: 40px;

  &:not(:first-child) {
    @apply -ml-2;
  }
}
