@use 'variables' as *;

$mat-menu-outer-offset: 1px;
$border-menu-width: 1px;
$menu-header-space-height: 54px;
$menu-header-org-space-height: 21px;
$menu-footer-height: 34px;
$menu-header-branches-height: 32px;

.mat-mdc-menu-panel {
  --mdc-theme-surface: theme('colors.neutral.0');
  --mdc-theme-on-surface: theme('colors.neutral.50');
  --mat-menu-container-shape: theme('spacing.4');
  --mdc-typography-body1-font-weight: theme('fontWeight.medium');
  border: #{$border-menu-width} solid theme('colors.neutral.200');

  .dark & {
    --mdc-typography-body1-font-weight: theme('fontWeight.medium-dark');
  }

  &.mat-mdc-menu-panel {
    max-width: 250px;
    min-width: 165px;
    max-height: 70vh;
    margin-top: theme('spacing.1');
    margin-bottom: theme('spacing.1');
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);

    &.topic-selection {
      max-width: 380px;
      min-width: 380px;
      min-height: 190px;

      @media screen and (max-width: 768px) {
        max-width: 100%;
        min-width: 100%;
      }
    }

    &.topic-shield-card-menu {
      max-width: 380px;
      min-width: 380px;
      min-height: 165px;

      @media screen and (max-width: 768px) {
        max-width: 100%;
        min-width: 100%;
      }
    }

    &.wide-width {
      max-width: 380px;
      min-width: 380px;

      @media screen and (max-width: 576px) {
        min-width: 80dvw;
      }
    }

    &.center-aligned {
      transform: translateX(-110px) !important;

      @media screen and (max-width: 768px) {
        transform: translateX(0) !important;
      }
    }

    &.no-width-constraints {
      max-width: unset;
    }

    .branch-selector-menu {
      width: 285px;
      display: flex;
      flex-direction: column;
    }
  }

  &.custom-menu-wrap.mat-mdc-menu-panel {
    overflow: hidden;

    .mat-mdc-menu-content {
      height: 100%;
    }
  }

  &.has-padding .mat-mdc-menu-content {
    @apply p-2;
  }

  .mat-mdc-menu-item {
    min-height: auto;
    --mat-menu-item-label-text-color: theme('colors.primary-exam');
    --mdc-list-list-item-hover-label-text-color: var(--primary-exam);
    --mdc-list-list-item-focus-label-text-color: var(--primary-exam);

    .mat-mdc-menu-item-text {
      --mat-menu-item-label-text-weight: theme('fontWeight.medium-exam');

      .dark & {
        --mat-menu-item-label-text-weight: theme('fontWeight.medium-exam');
      }
    }

    &.mat-mdc-menu-item-submenu-trigger {
      @apply pr-3;
    }

    &[disabled] {
      opacity: 1;

      .mat-mdc-menu-item-text {
        @apply text-typography-disabled;
      }
    }

    &:not([disabled]) {
      &.cdk-program-focused,
      &.cdk-keyboard-focused,
      &.mat-mdc-menu-item-highlighted {
        @apply bg-sky-50;
      }
    }

    &:visited,
    &:link {
      @apply text-typography-primary;
    }
  }

  .mat-mdc-menu-content {
    @apply py-0;

    > * {
      height: 100%;
    }
  }

  .mat-mdc-menu-content,
  .mat-mdc-menu-content .mat-mdc-menu-item-text {
    --mat-menu-item-label-text-size: theme('fontSize.md.0');
    --mat-menu-item-label-text-line-height: theme('fontSize.md.1');
  }

  .section-title.mat-mdc-menu-item {
    &:hover {
      @apply bg-sky-50;
      cursor: default;
    }

    .mat-mdc-menu-item-text {
      @apply text-typography-secondary text-md;
    }
  }

  .mat-mdc-menu-item.mat-menu-item-readonly:not(:disabled) {
    &:hover {
      @apply bg-background-secondary;
      cursor: default;
    }
  }

  .menu-footer,
  .menu-header {
    @apply bg-neutral-50;
  }

  .menu-header-search {
    @apply dark:shadow-scrollable shadow-scrollable-light;
  }

  .custom-menu-inner {
    height: 100%;
  }

  .custom-menu-inner-content {
    height: calc(100% - #{$menu-footer-height});
    display: flex;
    flex-direction: column;
  }

  .custom-menu-items {
    overflow-y: auto;

    &.branches-dropdown-items:not(:empty) {
      height: calc(100% - #{$menu-header-branches-height});
      max-height: 300px;
    }
  }

  .menu-footer {
    padding-top: $mat-menu-outer-offset;
    border-top: 1px solid var(--neutral-200);
  }

  .mat-mdc-menu-submenu-icon {
    display: none;
  }

  .mat-mdc-menu-item-text {
    display: flex;
    align-items: center;
    width: 100%;

    .menu-item-label {
      &:not(:last-child) {
        @apply mr-1;
      }

      &:not(:first-child) {
        @apply ml-1;
      }
    }
  }

  .mat-menu-icons-right {
    margin-left: auto;
  }

  .empty-text {
    @apply text-secondary p-4;

    &:hover {
      cursor: default;
    }
  }

  .custom-mat-form-wrapper {
    width: 100%;

    &.filterable-list {
      .mat-mdc-form-field .mdc-text-field {
        @apply px-4;
        border: none;
      }
    }

    .mat-mdc-form-field {
      width: 100%;
    }
  }
}

//Common styling for mtx-popover(custom-mat-menu) and mat-menu
.mat-mdc-menu-panel,
.custom-mat-menu {
  .mat-mdc-menu-item,
  .custom-mat-menu-item {
    @apply px-4;
    min-height: 48px;

    &.active {
      @apply bg-sky-50 text-sky-500;
    }

    + .mat-mdc-menu-item,
    + .menu-footer,
    + .custom-mat-menu-item,
    + .section-title {
      margin-top: $mat-menu-outer-offset;
    }

    &.danger-button span,
    &.danger-button {
      @apply text-typography-danger-primary;
    }

    &:hover:not([disabled]),
    &.mat-mdc-menu-item-highlighted {
      @apply bg-sky-50;
    }
  }

  .menu-item-wrapper.has-border-top,
  .mat-mdc-menu-item.has-border-top {
    position: relative;

    &::before {
      @apply bg-neutral-200;
      position: absolute;
      height: $border-menu-width;
      top: calc(-1 * (#{$mat-menu-outer-offset} + #{$border-menu-width}));
      width: 100%;
      content: '';
    }
  }

  .menu-item-wrapper,
  .mat-mdc-menu-item {
    + .mat-mdc-menu-item,
    + .menu-item-wrapper {
      margin-top: $mat-menu-outer-offset;

      &.has-border-top {
        margin-top: calc(#{$mat-menu-outer-offset * 2} + #{$border-menu-width});
      }
    }
  }

  .mat-mdc-menu-item.has-border-top {
    overflow: inherit;
  }

  .section-title {
    @apply border-0 border-b border-solid border-neutral-200 bg-neutral-50 px-4 py-3;
    position: sticky;
    top: 0;
    z-index: 1;

    + .custom-mat-menu-item,
    + .mat-mdc-radio-group,
    + .mat-mdc-menu-item {
      margin-top: $mat-menu-outer-offset;
    }
  }

  .menu-icon,
  .custom-mat-menu-icon {
    padding: 1px;
    width: $menu-icon-wrapper-size;
    height: $menu-icon-wrapper-size;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    > fa-icon {
      display: inline-flex;
    }
  }
}
