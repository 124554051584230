$toast-width: 400px;

.hot-toast-bar-base {
  --hot-toast-bg: var(--gray-100);
  --hot-toast-border-radius: theme('borderRadius.medium');
  --hot-toast-shadow: 1px 4px 14px rgba(0, 0, 0, 0.3);
  --hot-toast-color: var(--typography-primary);
  --hot-toast-margin: theme('spacing.4');
  --hot-toast-max-width: #{$toast-width};
  --hot-toast-padding: theme('spacing.4');
  --hot-toast-pointer-events: all;
  --hot-toast-width: #{$toast-width};
  position: relative;
  border: 1px solid var(--gray-300);

  @media screen and (max-width: 500px) {
    --hot-toast-max-width: 100%;
    --hot-toast-width: 100%;
  }

  .hot-toast-icon {
    --hot-toast-icon-padding-top: 0;
    display: none;
  }

  .hot-toast-message {
    --hot-toast-message-color: var(--typography-primary);
    --hot-toast-message-margin: 0;
    --hot-toast-message-justify-content: flex-start;

    > * {
      width: 100%;
    }
  }
}

//Custom toast icon's colors
.toast-type-icon {
  .hot-toast-success & {
    color: var(--green-800);
  }

  .hot-toast-error & {
    color: var(--red-800);
  }

  .hot-toast-warning & {
    color: var(--yellow-1000);
  }

  .hot-toast-info & {
    color: var(--typography-secondary);
  }

  .hot-toast-loader & {
    color: var(--typography-primary);
  }
}
