@use 'sass:meta';
@use 'basic-setup-styles'; //do not change this
@use 'angular-material/components/mat-input';
@use 'angular-material/components/mat-ripple';
@use 'common/empty-states';
@use 'hot-toast/hot-toast';

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

@include meta.load-css('theme/typography');
@include meta.load-css('theme/override');
@include meta.load-css('theme/global');
@include meta.load-css('theme/material/mat-buttons/mat-button');
@include meta.load-css('theme/material/mat-buttons/mat-button-colors');
@include meta.load-css('theme/material/mat-input');
@include meta.load-css('theme/material/mat-form');
@include meta.load-css('theme/material/mat-select');
@include meta.load-css('theme/material/mat-autocomplete');
@include meta.load-css('theme/material/mat-list');
@include meta.load-css('theme/material/mat-progress-bar');
@include meta.load-css('theme/material/mat-radio');
@include meta.load-css('theme/material/mat-checkbox');
@include meta.load-css('theme/material/mat-tooltip');
@include meta.load-css('theme/material/mat-tab');
@include meta.load-css('theme/material/mat-accordion');
@include meta.load-css('theme/material/mat-toggle-button');
@include meta.load-css('theme/material/mat-slide-toggle');
@include meta.load-css('theme/material/mat-calendar');
@include meta.load-css('theme/material/mat-menu');
@include meta.load-css('theme/material/mat-slider');
@include meta.load-css('theme/material/ag-grid/ag-grid-themes');
@include meta.load-css('theme/angular-split/angular-split');

@import 'quill/dist/quill.bubble.css';
